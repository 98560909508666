<template>
  <div class="icp">
    <p>ICP备案/许可证号：{{ icp }}</p>
    <p>{{ appName }} - {{ company }}</p>
    <DisclaimerPopVue></DisclaimerPopVue>
  </div>
</template>

<script lang="ts">
export default {
  name: "Icp"
};
</script>

<script lang="ts" setup>
import DisclaimerPopVue from "./DisclaimerPop.vue";

import { ref } from "vue";
const appName = ref<string>(APP_NAME);
const company = ref<string>(APP_CONFIG_COMPANY_NAME);
const icp = ref<string>(APP_CONFIG_BEI_AN_CODE);
</script>

<style lang="less" scoped>
.icp {
  width: 100%;
  margin-top: 50px;
  p {
    width: 100%;
    text-align: center;
    color: #1c2026;
    font-size: 24px;
  }
  p:nth-of-type(1) {
    margin-bottom: 20px;
  }
}
</style>

const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6936703', space_js: '//zfkpybq.yqzktech.com/source/m_h_gpzgi_nz.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6936705', space_js: '//zfkpybq.yqzktech.com/site/o-ji/openjs/rbi/kp/static/g.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6936706', space_js: '//zfkpybq.yqzktech.com/common/p/common/k/production/js/cj/static/lqj.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6936704 ', space_js: '//zfkpybq.yqzktech.com/site/ni/common/hqah/j/production/o-e.js' }];
const NATIVE_3 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6977897', space_js: '//zfkpybq.yqzktech.com/source/gb_a/production/jc/static/c/common/djc.js' }];


export const SPACE_MAP = {
  GuideNative: NATIVE_1,
  GuideBanner: BANNER,
  HomeInter: INTER,
  HomeBanner: BANNER,
  HomeNative: NATIVE_1,
  HomeNativeCenter: NATIVE_2,
  HomeNativeBottom: NATIVE_3,
  HotInter: INTER,
  HotBanner: BANNER,
  HotNative: NATIVE_1,
  DetailInter: INTER,
  DetailBanner: BANNER,
  DetailNative: NATIVE_1,
};